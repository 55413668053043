import React, { useMemo } from "react";
import { Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { useSettlementCounterUIContext } from "../SettlementCounterUIContext";
import { currencyFormatter, toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import * as columnFormatters from './column-formatters';
import moment from "moment";

export function DetailPaymentDialog() {

    const uiContext = useSettlementCounterUIContext();
    const uiProps = useMemo(() => {
        return {
            showDetailPay: uiContext.showDetailPay,
            setShowDetailPay: uiContext.setShowDetailPay,
            donationPayments: uiContext.donationPayments,
        }
    });

    const columns = [
        {
            dataField: 'donation_hid',
            text: 'Donation HID',
        },
        {
            dataField: 'donor_name',
            text: 'Nama Donatur',
        },
        {
            dataField: 'total_items',
            text: 'Total Item',
        },
        {
            dataField: 'amount',
            text: 'Jumlah Pembayaran',
            formatter: (cell) => currencyFormatter(cell || 0)
        },
        {
            dataField: 'trx_date',
            text: 'Tanggal Transaksi',
            formatter: (cell) => moment(cell).format('DD-MMM-YYYY')
        },
        {
            dataField: 'created_at',
            text: 'Dibuat pada',
            formatter: (cell) => moment(cell).format('DD-MMM-YYYY HH:mm:ss')
        },
        {
            dataField: 'action',
            text: 'Actions',
            formatter: (_, row) => columnFormatters.ActionsColumnFormatter(row),
            classes: 'text-right pr-0',
            headerClasses: 'text-center pr-0',
            style: {
                minWidth: '100px',
            },
        }
    ];

    const name = `${uiProps.showDetailPay.detailData.type} - ${uiProps.showDetailPay.detailData.name}` || '';
    const totalAmount = currencyFormatter(uiProps.showDetailPay.detailData.total_amount || 0);

    return (
        <Modal 
            size="xl"
            show={uiProps.showDetailPay.open}
            onHide={() => uiProps.setShowDetailPay({ open: false, detailData: {}})}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header>
                <Modal.Title id="example-modal-sizes-title-lg">Detail Pembarayan {name} | Total Pembayaran {totalAmount}</Modal.Title>
                <span className="svg-icon svg-icon-xl svg-icon-primary" onClick={() => uiProps.setShowDetailPay({ open: false, detailData: {}})}>
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")} />
                </span>
            </Modal.Header>
            <Modal.Body>
                <BootstrapTable
                    wrapperClasses="table-responsive"
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                    bordered={false}
                    remote
                    keyField="uuid"
                    data={uiProps.donationPayments}
                    columns={columns}
                />
            </Modal.Body>
        </Modal>
    );   
}