import React, { useMemo } from 'react';
import { Card, CardBody, CardFooter, CardHeader, CardHeaderToolbar } from '../../../../../_metronic/_partials/controls';
import { Badge } from 'react-bootstrap';
import { Divider, Typography } from '@material-ui/core';
import CurrencyInput from 'react-currency-input-field';
import AsynSelect from "react-select/async";
import { useSettlementCounterUIContext } from './SettlementCounterUIContext';
import { DetailPaymentDialog } from './dialog/DetailPaymentDialog';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { currencyFormatter } from '../../../../../_metronic/_helpers';
import { toast } from 'react-toastify';
import { SettlementApprovalDialog } from './dialog/SettlementApprovalDialog';

export function SettlementCounterCard() {
    const uiContext = useSettlementCounterUIContext();
    const uiProps = useMemo(() => {
        return {
            userData: uiContext.userData,
            cardPayments: uiContext.cardPayments,
            reconcilePayments: uiContext.reconcilePayments,
            defaultBankPoolingOptions: uiContext.defaultBankPoolingOptions,
            loadBankPoolingOptions: uiContext.loadBankPoolingOptions,
            inputData: uiContext.inputData,
            updateInputData: uiContext.updateInputData,
            selectDate: uiContext.selectDate,
            setSelectDate: uiContext.setSelectDate,
            selectCard: uiContext.selectCard,
            setSelectCard: uiContext.setSelectCard,
            setShowDetailPay: uiContext.setShowDetailPay,
            setShowSettleApproval: uiContext.setShowSettleApproval,
            setCardPayments: uiContext.setCardPayments,
        }
    });

    const disableButton = 
        !uiProps.inputData.bank_pooling_uuid || 
        !uiProps.inputData.payment_name || 
        !uiProps.inputData.payment_type || 
        !uiProps.inputData.amount ||
        !uiProps.inputData.file ||
        uiProps.inputData.donation_payment_uuids.length === 0 

    return (
        <>
            <Card>
                <CardHeader title="Settlement Data">
                    <CardHeaderToolbar>
                        <Typography variant='subtitle1'>{uiProps.userData.name} - {uiProps.userData.role}</Typography>
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    {/* Filter date */}
                    <div className="row mb-10" style={{ gap: '0.5rem'}}>
                        {
                            uiProps.reconcilePayments.map((v, index) => (
                                <Badge
                                    key={index}
                                    className="px-6 py-3" 
                                    pill 
                                    variant={ uiProps.selectDate.index === index ? 'primary' : 'light'}
                                    data-value={v.created_at}
                                    onClick={(e) => {
                                        uiProps.setSelectDate({ index, date: e.target.dataset.value });

                                        // select card
                                        const cardPayments = uiProps.reconcilePayments.find((v) => v.created_at === e.target.dataset.value);
                                        uiProps.setCardPayments(cardPayments.data);
                                        uiProps.setSelectCard({ index: 0, data: cardPayments.data[0] });
                                    }}
                                >
                                    {v.created_at}
                                </Badge>
                            ))
                        }
                    </div>
                    
                    <h5>Daftar Pembayaran</h5>
                    <div className="row my-6" style={{marginLeft: '1px'}}>
                        {uiProps.cardPayments.map((v, index) => (
                            <div 
                                key={index}
                                className={uiProps.selectCard.index === index ? 'bg-primary mr-3 mb-2' : 'bg-light mr-3 mb-2'} 
                                style={{ width: '26.3rem', borderRadius: '5px', padding: '1rem', color: uiProps.selectCard.index === index ? 'white' : 'black'}}
                                onClick={(e) => uiProps.setSelectCard({ index, data: uiProps.cardPayments[index]})}
                            >   
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <Typography variant='caption'>{v.payment_type !== 'CASH' ? 'NON CASH' : v.payment_type}</Typography>
                                        <Typography variant='body2' style={{fontSize: '14px'}}>{`${v.payment_type} - ${v.payment_name}` || ''}</Typography>
                                    </div>
                                    <OverlayTrigger
		                            	overlay={<Tooltip id="products-edit-tooltip">Lihat Detail Pembarayan</Tooltip>}
		                            >
		                            	<a
		                            		className={uiProps.selectCard.index === index ? "btn btn-light btn-sm" : "btn btn-primary btn-sm"}
                                            style={{ fontSize: '10px'}}
		                            		onClick={() => uiProps.setShowDetailPay({ 
                                                open: true, 
                                                detailData: { 
                                                    uuid: v.payment_uuid,
                                                    name: v.payment_name, 
                                                    type: v.payment_type,
                                                    total_amount: v.total_amount 
                                                }})}
		                            	>
                                            Lihat Detail
		                            	</a>
		                            </OverlayTrigger>
                                </div>
                                <Divider className={uiProps.selectCard.index === index ? 'my-2 bg-light' : 'my-2 bg-secondary'} />
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <div>
                                        <Typography variant='caption'>Total Pembayaran</Typography>
                                        <Typography variant='subtitle2' style={{fontSize: '16px'}}>{currencyFormatter(v.total_amount || 0)}</Typography>
                                    </div>
                            
                                    <Typography variant='subtitle2'>{v.total_transaksi || 0} Transaksi</Typography>
                                </div>
                            </div>
                        ))}
                    </div>

                    <Card>
                        <CardHeader title={
                            "Settlement " + 
                            `- ${uiProps.selectCard.data.payment_name || 'Kosong'} | Total Pembayaran: ${currencyFormatter(uiProps.selectCard.data.total_amount)}`
                            } />
                        <CardBody>
                            <div className="row my-5">
                                <div className="col-lg pb-5" >
                                    <label>*Bank Pengumpul</label>
                                    <AsynSelect 
                                        placeholder={'*Pilih Bank Pengumpul...'}
                                        defaultOptions={uiProps.defaultBankPoolingOptions}
                                        loadOptions={uiProps.loadBankPoolingOptions}
                                        onChange={(e) => {
                                            uiProps.updateInputData('bank_pooling_uuid', e.value);
                                            uiProps.updateInputData('bank_pooling_name', e.label);
                                        }}
                                    />
                                </div>

                                <div className="col-lg pb-5">
                                    <label htmlFor="channel_charge">Input Biaya</label>
                                    <CurrencyInput
                                        name="channel_charge"
                                        placeholder="Nominal Pada Bank/EDC"
                                        intlConfig={{ locale: 'id-ID', currency: 'IDR' }}
                                        className="form-control"
                                        defaultValue={0}
                                        value={uiProps.inputData.channel_charge}
                                        onValueChange={(value) => uiProps.updateInputData('channel_charge', value)}
                                        decimalsLimit={2}
                                        label="Jumlah"
                                    />
                                </div>

                                <div className="col-lg pb-5">
                                    <label htmlFor="diff_amount">Selisih Nominal</label>
                                    <CurrencyInput
                                        name="diff_amount"
                                        placeholder="Selisih Nominal"
                                        intlConfig={{ locale: 'id-ID', currency: 'IDR' }}
                                        className="form-control"
                                        value={uiProps.inputData.diff_amount}
                                        decimalsLimit={2}
                                        label="Jumlah"
                                        disabled={true}
                                    />
                                </div>

                                <div className="col-lg pb-5">
                                    <label htmlFor="diff_description">Deskripsi Selisih</label>
                                    <input
                                        name="diff_description"
                                        className="form-control"
                                        value={uiProps.inputData.diff_description}
                                        onChange={(e) => uiProps.updateInputData('diff_description', e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg">
                                    <label htmlFor="diff_description">*Upload Gambar (jpg, jpeg, png)</label>
                                    <input 
                                        id="file"
                                        label="Bukti Transfer"
                                        type='file'
                                        style={{border: 'none'}}
                                        className="form-control"
                                        onChange={(e) => {
                                            const file = e.target.files[0];
                                            if (file && !['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)) {
                                                toast.error("Hanya file JPG, JPEG, atau PNG yang diperbolehkan!");
                                                e.target.value = null;
                                                return;
                                            }

                                            uiProps.updateInputData('file', file);
                                        }}
                                    />
                                </div>
                            </div>
                        </CardBody>
                        <CardFooter>
                            <button
                                type="button"
                                disabled={disableButton}
                                className="btn btn-success float-right mx-1"
                                onClick={() => {
                                    uiProps.setShowSettleApproval({ 
                                        open: true, 
                                        detailData: { 
                                            uuid: uiProps.selectCard.data.payment_uuid,
                                            name: uiProps.selectCard.data.payment_name, 
                                            type: uiProps.selectCard.data.payment_type,
                                            total_amount: uiProps.selectCard.data.total_amount 
                                        }});
                                }}
                            > 
                                Settle
                            </button>   
                        </CardFooter>
                    </Card>
                </CardBody>
            </Card>

            {/* Modal Detail Payment */}
            <DetailPaymentDialog />

            {/* Modal Settlement Approval */}
            <SettlementApprovalDialog />
        </>
    )
}