/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const ActionsColumnFormatter = (
    row,
) => {
    return (
        <div className="text-center">
            <OverlayTrigger
                overlay={<Tooltip id="donation-edit-tooltip">View Detail</Tooltip>}
            >
                <a href={`/donasi/${row.uuid}/detail-v2`} className="text-light" target="_blank" rel="noopener noreferrer">
                    <button className="btn btn-sm btn-primary mb-1">
                        Detail Donasi
                    </button>
                </a>
            </OverlayTrigger>
        </div>
    )
}
