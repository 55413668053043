import React, { useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { useSettlementCounterUIContext } from "../SettlementCounterUIContext";
import { currencyFormatter, toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import * as columnFormatters from './column-formatters';
import moment from "moment";

export function SettlementApprovalDialog() {
    const uiContext = useSettlementCounterUIContext();
    const uiProps = useMemo(() => {
        return {
            donationPayments: uiContext.donationPayments,
            createSettlement: uiContext.createSettlement,
            showSettleApproval: uiContext.showSettleApproval,
            setShowSettleApproval: uiContext.setShowSettleApproval,
            inputData: uiContext.inputData,
            isChecked: uiContext.isChecked, 
            setIsChecked: uiContext.setIsChecked
        }
    });

    const columns = [
        {
            dataField: 'donation_hid',
            text: 'Donation HID',
        },
        {
            dataField: 'amount',
            text: 'Jumlah Pembayaran',
            formatter: (cell) => currencyFormatter(cell || 0)
        },
        {
            dataField: 'created_at',
            text: 'Dibuat pada',
            formatter: (cell) => moment(cell).format('DD-MMM-YYYY HH:mm:ss')
        },
        {
            dataField: 'action',
            text: 'Actions',
            formatter: (_, row) => columnFormatters.ActionsColumnFormatter(row),
            classes: 'text-right pr-0',
            headerClasses: 'text-center pr-0',
            style: {
                minWidth: '100px',
            },
        }
    ];

    return (
        <Modal 
            size="xl"
            show={uiProps.showSettleApproval.open}
            onHide={() => uiProps.setShowSettleApproval({ open: false, detailData: {}})}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header>
                <Modal.Title id="example-modal-sizes-title-lg">Persetujuan Settlement</Modal.Title>
                <span className="svg-icon svg-icon-xl svg-icon-primary" onClick={() => uiProps.setShowSettleApproval({ open: false, detailData: {}})}>
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")} />
                </span>
            </Modal.Header>
            <Modal.Body>
                <BootstrapTable
                    wrapperClasses="table-responsive"
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                    bordered={false}
                    remote
                    keyField="uuid"
                    data={uiProps.donationPayments}
                    columns={columns}
                />

                <hr className="my-4" />

                <div className="form-group row mt-12">
                    <div className="col-lg-8 text-right mt-3">
                        <h6>Bank Pengumpul</h6>
                    </div>
                    <div className="col-lg-4">
                        <input
                            disabled
                            name="total_amount"
                            className="form-control font-weight-bold text-right"
                            value={uiProps.inputData.bank_pooling_name}
                        />
                    </div>
                </div>

                <div className="form-group row mt-5">
                    <div className="col-lg-8 text-right mt-3">
                        <h6>Total Donasi</h6>
                    </div>
                    <div className="col-lg-4">
                        <input
                            disabled
                            name="total_amount"
                            className="form-control font-weight-bold text-right"
                            value={`${currencyFormatter(uiProps.inputData.amount.toString())}`}
                        />
                    </div>
                </div>

                <div className="form-group row mt-5">
                    <div className="col-lg-8 text-right mt-3">
                        <h6>Biaya Channel</h6>
                    </div>
                    <div className="col-lg-4">
                        <input
                            disabled
                            name="total_amount"
                            className="form-control font-weight-bold text-right"
                            value={`${currencyFormatter(uiProps.inputData.channel_charge ? uiProps.inputData.channel_charge.toString() : '0')}`}
                        />
                    </div>
                </div>

                <div className="form-group row mt-5">
                    <div className="col-lg-8 text-right mt-3">
                        <h6>Saldo Akhir</h6>
                    </div>
                    <div className="col-lg-4">
                        <input
                            disabled
                            name="total_amount"
                            className="form-control font-weight-bold text-right"
                            value={`
                                ${currencyFormatter(
                                    uiProps.inputData.diff_amount === 0 ? 
                                    uiProps.inputData.amount.toString() : 
                                    uiProps.inputData.diff_amount.toString())
                                }`}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex flex-column align-items-end">
                <div className="d-flex align-items-center">
                    <input
                        type="checkbox"
                        checked={uiProps.isChecked}
                        onChange={(e) => uiProps.setIsChecked(e.target.checked)}
                        id="dataAgreement"
                        className="mr-2"
                    />
                    <label htmlFor="dataAgreement" className="pt-2" onChange={(e) => uiProps.setIsChecked(e.target.checked)}>
                        <b>
                            Dengan ini, Saya menyetujui bahwa data Settle sudah Benar dan
                            Sesuai, dan Saya Bertanggung Jawab Penuh terhadap data ini.
                        </b>
                    </label>
                </div>

                <div className="d-flex flex-row" style={{ gap: '5px' }}>
                    <Button
                        variant="light"
                        onClick={() => uiProps.setShowSettleApproval({ open: false, detailData: {}})}
                    >
                        Cancel
                    </Button>

                    <Button
                        variant="primary"
                        disabled={!uiProps.isChecked}
                        onClick={() => uiProps.createSettlement()}
                    >
                        Settle Data
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );   
}