import React, { useCallback, useEffect, useState } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { ContentRoute, Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import RedirectDonationReport from "./redirect-page/RedirectDonationReport";
import { getDonationPayment } from "./modules/Donation/_redux/donation/donationCrud";
import moment from "moment";
import { Button, Modal } from "react-bootstrap";
import { useLocation, useHistory } from "react-router-dom";

export function Routes() {
    const { isAuthorized, user } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.user != null,
            user: auth?.user,
        }),
        shallowEqual
    );

    const [showAlertSettle, setShowAlertSettle] = useState(false);
    const [redirectPath, setRedirectPath] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [messageAlert, setMessageAlert] = useState({ header: '', body: ''});

    const location = useLocation();
    const history = useHistory();

    // Fetch Donation Payment
    const fetchDonations = useCallback(async (query) => {
        try {
            const queryParams = {
                filter: { user_uuid: query.uuid, status_dona: "PAID" },
                pageNumber: 1,
                pageSize: 100,
            };
            const result = await getDonationPayment(queryParams);
            const items = result.data.data.items;
            const threeDaysAgo = moment().subtract(3, "days").startOf("day");
            const today = moment().startOf("day");

            if (
                items.length >= 1 && 
                (moment(items[0].created_at).startOf("day") < today && moment(items[0].created_at).startOf("day") > threeDaysAgo)
            ) {
                setShowAlertSettle(true);
                setMessageAlert({
                    header: 'Segera Selesaikan Proses Settlement !!',
                    body: `
                        Anda memiliki donasi yang telah dibayar dalam kurun waktu 1-2 hari terakhir. <br></br>
                        Harap selesaikan segera ${items.length} proses <b>Settlement</b> !!
                        sebelum akun terblokir dan tidak dapat melakukan <b>Input Donasi</b>.
                    `
                })
            }

            // if expired settlement [3 to a few days ago]
            if (items.length >= 1 && moment(items[0].created_at).startOf("day") <= threeDaysAgo) {
                localStorage.setItem("bannedDonation", "true");
                setShowAlertSettle(true);
                setMessageAlert({
                    header: 'Akun Anda Telah Diblokir !!',
                    body: `
                        Saat ini akun telah diblokir dan tidak bisa melakukan <b>Input Donasi</b>. <br></br>
                        Harap selesaikan ${items.length} proses <b>Settlement</b> terlebih dahulu !!
                        lalu login ulang kembali untuk membuka blokir.
                    `
                })
            }

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error("Failed to fetch donation payment data:", error);
        } 
    }, []);

    // Handle User State
    useEffect(() => {
        if (!user) {
            localStorage.removeItem("bannedDonation");
            localStorage.removeItem("hideAlert");
            setShowAlertSettle(false);
            setIsLoading(false);
        } else if (user.role === "Fundraiser" && !localStorage.getItem("hideAlert")) {
            fetchDonations(user);
        } else {
            setIsLoading(false);
        }
    }, [user, fetchDonations, setIsLoading]);

    // Handle Redirection Path
    useEffect(() => {
        if (isLoading) return;

        let path = "/";
        if (!isAuthorized) path = "/auth/login";     
        else if (showAlertSettle) path = "/donasi/settlement/counter";
        else if (localStorage.getItem("hideAlert")) path = "/donasi/settlement/counter";
        else if (!localStorage.getItem("hideAlert") && user?.is_change_password) path = "/user-profile/change-password";
        
        setRedirectPath(path);
    }, [isAuthorized, user, showAlertSettle, isLoading]);

    const dynamicLocation = location.pathname === '/auth/error/error-v1' || location.pathname === '/';

    // Loading Indicator
    if (isLoading) {
        return (
            <>Loading..</>
        );
    }

    return (
        <Switch>
            {/* Laporan Kwitansi */}
            <ContentRoute path="/report/kwitansi/:id" component={RedirectDonationReport} />

            {/* Halaman Error */}
            <Route path="/error" component={ErrorsPage} />

            {/* Logout */}
            <Route path="/logout" component={Logout} />

            {/* Auth Routes */}
            {!isAuthorized && (
                <>
                    <Route path="/auth" component={AuthPage} />
                    <Redirect to="/auth/login" />
                </>
            )}

            {/* Protected Routes */}
            {isAuthorized && (
                <Layout>
                    {/* Redirect Dinamis */}
                    {redirectPath && dynamicLocation && !isLoading && <Redirect to={redirectPath} />}
                    
                    {/* Main Page */}
                    <Route path="/" component={BasePage} />

                    {/* Modal Alert */}
                    <Modal size="md" show={showAlertSettle}>
                        <Modal.Header>
                            <Modal.Title>{messageAlert.header}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body dangerouslySetInnerHTML={{ __html: messageAlert.body }} />
                        <Modal.Footer>
                            <Button
                                color="primary"
                                onClick={() => {
                                    localStorage.setItem("hideAlert", "true");
                                    setShowAlertSettle(false);
                                    history.push("/donasi/settlement/counter");
                                }}
                            >
                                Mengerti
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Layout>
            )}

            {/* Fallback ke Halaman Error */}
            <Redirect to="/error" />
        </Switch>
    );
}
