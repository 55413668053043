import React from 'react';
import { SettlementCounterUIProvider } from "./SettlementCounterUIContext";
import { SettlementCounterCard } from './SettlementCounterCard';


export function SettlementCounterPage({ history }) {
    const uiEvent = {
        openDetail: (type) => {
            history.pushState(`/donasi/settlement/${type}/detail`);
        }
    }

    return (
        <SettlementCounterUIProvider uiEvent={uiEvent} history={history}>
            <SettlementCounterCard />
        </SettlementCounterUIProvider>
    );
}