import React, { Suspense, lazy } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { DashboardPage } from "./pages/DashboardPage";
import { MyPage } from "./pages/MyPage";
import { DonaturPage } from "./modules/Donatur/DonaturPage";
import { DonaturEdit } from "./modules/Donatur/donatur-edit/DonaturEdit";
import { CollectorPage2 } from "./modules/Collector2/CollectorPage";
import { CollectPage2 } from "./modules/Collector2/collect/CollectPage";
import { CheckerPage2 } from "./modules/Checker2/CheckerPage";
import { CheckPage2 } from "./modules/Checker2/check/CheckPage";
import { shallowEqual, useSelector } from "react-redux";
import { PrivateRoute } from "./PrivateRoute";
import { ExportListPage } from "./modules/ExportList/ExportListPage";
import { RoleChecker } from "./RoleChecker";
import { DoubleNamePage } from "./modules/Donatur/donatur-double-name/DoubleName";
import { DoubleRekeningPage } from "./modules/Donatur/donatur-double-rekening/DoubleRekening";
import { DonorImportPage } from "./modules/DonorImport/DonorImportPage";
import { DonorImportEdit } from "./modules/DonorImport/donor-import-edit/DonorImportEdit";
import { PartnershipPage } from "./modules/Kurban/partnership/pages/PartnershipPage";
import { PartnershipEditDialog } from "./modules/Kurban/partnership/pages/partnership-edit-dialog/PartnershipEditDialog";
import { PartnershipManageCard } from "./modules/Kurban/partnership/pages/partnership-manage/PartnershipManageCard";
import ThkImportForm from "./modules/Kurban/partnership/pages/partnership-manage/thk-import/ThkImportForm";
import DetailImportBatch from "./modules/Kurban/partnership/pages/partnership-manage/thk-import/DetailImportBatch";
import { PeriodTemplatePage } from "./modules/Kurban/period-template/pages/PeriodTemplatePage";
import { PeriodTemplateEditDialog } from "./modules/Kurban/period-template/pages/period-template-edit-dialog/PeriodTemplateEditDialog";
import { KurbanPage } from "./modules/Donation/pages/kurban/kurban-list/KurbanPage";
import { HewanKurbanPage } from "./modules/Donation/pages/kurban/hewan-kurban/HewanKurbanPage";
import { HewanKurbanCampaignPage } from "./modules/Donation/pages/kurban/hewan-kurban-campaign/HewanKurbanCampaignPage";
import { ThkReportTemplateCard } from "./modules/Kurban/partnership/pages/partnership-manage/thk-report/ThkReportCard";
import { ThkChannelReportPage } from "./modules/Kurban/thk-channel-report/pages/ThkChannelReportPage";
import { ThkChannelReportEditDialog } from "./modules/Kurban/thk-channel-report/pages/thk-channel-report-edit-dialog/ThkChannelReportEditDialog";
import ThkChannelReportManage from "./modules/Kurban/thk-channel-report/pages/thk-channel-report-donation-list/ThkChannelReportManage";
import { DonationExportPage } from "./modules/Export/donationExport/pages/DonationExportPage";
import { AolConfigPage } from "./modules/AolConfig/AolConfigPage";
import ChangePassword from "./modules/UserProfile/ChangePassword";
import { AolBigQueryPage } from "./modules/AolBigQuery/AolBigQueryPage";
import { SettlementCounterPage } from "./modules/Donation/pages/settlement-counter/SettlementCounterPage";

const ProjectProgramPage = lazy(() => import("./modules/ProgramProject/pages/ProjectProgramPage"));
const MasterDatapage = lazy(() =>
  import("./modules/MasterData/pages/MasterDataPage")
);
const DonationPage = lazy(() =>
  import("./modules/Donation/pages/DonationPage")
);
const AnalysisPage = lazy(() =>
  import("./modules/Analysis/pages/AnalysisPage")
);

export default function BasePage() {
  // const { role } = useSelector(
  //   (state) => ({ role: state.auth.user.role }),
  //   shallowEqual
  // );

  const user = useSelector(({ auth }) => auth.user, shallowEqual);
  const { role, is_change_password } = user;

  // List Roles Navigasi
  const donasi_roles = [
    "SuperAdmin",
    "Fundraiser",
    "DataProses",
    "CustomerCare",
    "AdminCabang",
    "Pickup",
    "FundCollCheck",
    "KepalaCabang",
    "Helpdesk",
    "MitraEchannel",
  ];
  const checker_roles = [
    "SuperAdmin",
    "Checker",
    "FundCollCheck",
    "KepalaCabang",
  ];
  const donatur_roles = [
    "SuperAdmin",
    "DataProses",
    "CustomerCare",
    "Fundraiser",
    "AdminCabang",
    "FundCollCheck",
    "KepalaCabang",
  ];
  const collector_roles = [
    "SuperAdmin",
    "Collector",
    "FundCollCheck",
    "KepalaCabang",
  ];
  const master_roles = [
    "SuperAdmin",
    "DataProses",
    "AdminCabang",
    "Helpdesk",
    "KepalaCabang",
  ];
  const export_roles = [
    "SuperAdmin",
    "DataProses",
    "AdminCabang",
    "Helpdesk",
    "KepalaCabang",
  ];
  const analysis_roles = [
    "SuperAdmin",
    "Fundraiser",
    "DataProses",
    "CustomerCare",
    "AdminCabang",
    "Pickup",
    "FundCollCheck",
    "KepalaCabang",
    "Helpdesk",
    "MitraEchannel",
  ];

  // const laporan_roles = ['SuperAdmin', 'DataProses', 'AdminCabang']
  // End List Roles

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }

        {/* GLOBAL */}
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        {/* <ContentRoute path="/setting" component={UserProfilePage} /> */}

        {/* DONASI ROLE */}
        <PrivateRoute
          role={RoleChecker("Read", "donasi")}
          component={DonationPage}
          path="/donasi"
        />

        {/* MASTER ROLE */}
        <PrivateRoute
          role={RoleChecker("Read", "master_data")}
          component={MasterDatapage}
          path="/master-data"
        />

        {/* PROGRAM & PROJECT */}
        <PrivateRoute
          role={RoleChecker("Read", "master_data")}
          component={ProjectProgramPage}
          path="/program-project"
        />

        {/* CHECKER ROLE
                <PrivateRoute role={checker_roles.includes(role)} component={CheckerPage} path="/checker/:id/:status"
                              exact={true}/>
                <PrivateRoute role={checker_roles.includes(role)} component={CheckerPage} path="/checker" exact={true}/> */}

        {/*CHECKER ROLE 2*/}
        <PrivateRoute
          role={RoleChecker("Read", "checker")}
          component={CheckerPage2}
          path="/checker/:id/:status"
          exact={true}
        />
        <PrivateRoute
          role={RoleChecker("Read", "checker")}
          component={CheckerPage2}
          path="/checker"
          exact={true}
        />
        <PrivateRoute
          role={RoleChecker("Read", "checker")}
          component={CheckPage2}
          path="/checker/check"
          exact={true}
        />

        {/* DONATUR ROLE */}
        <PrivateRoute
          role={RoleChecker("Read", "donatur")}
          component={DonaturPage}
          path="/donatur"
          exact={true}
        />
        <PrivateRoute
          role={RoleChecker("Edit Donatur", "detail_donatur")}
          component={DoubleNamePage}
          path="/donatur/double/donatur-name"
          exact={true}
        />
        <PrivateRoute
          role={RoleChecker("Edit Donatur", "detail_donatur")}
          component={DoubleRekeningPage}
          path="/donatur/double/donatur-rekening"
          exact={true}
        />
        <PrivateRoute
          role={RoleChecker("Edit Donatur", "detail_donatur")}
          component={DonaturEdit}
          path="/donatur/:id/edit"
          exact={true}
        />
        <PrivateRoute
          role={RoleChecker("Create Donatur", "daftar_donatur")}
          component={DonaturEdit}
          path="/donatur/new"
          exact={true}
        />
        <PrivateRoute
          role={RoleChecker("Edit Donatur", "detail_donatur")}
          component={DonaturEdit}
          path="/donatur/:parent_uuid/:donatur_type"
          exact={true}
        />

        <PrivateRoute
          role={RoleChecker("Read", "donor_import")}
          component={DonorImportPage}
          path="/donatur-import/"
          exact={true}
        />
        <PrivateRoute
          role={RoleChecker("Create", "donor_import")}
          component={DonorImportEdit}
          path="/donatur-import/new"
          exact={true}
        />
        <PrivateRoute
          role={RoleChecker("Edit", "donor_import")}
          component={DonorImportEdit}
          path="/donatur-import/:id/edit"
          exact={true}
        />

        {/* COLLECTOR ROLE */}
        {/* <PrivateRoute role={collector_roles.includes(role)} component={CollectPage} path="/collector/collect"
                              exact/>
                <PrivateRoute role={collector_roles.includes(role)} component={CollectorPage}
                              path="/collector/:id/:status"
                              exact/>
                <PrivateRoute role={collector_roles.includes(role)} component={CollectorPage} path="/collector"
                              exact/> */}

        {/* COLLECTOR 2 ROLE */}
        <PrivateRoute
          role={RoleChecker("Read", "collector")}
          component={CollectPage2}
          path="/collector/collect"
          exact
        />
        <PrivateRoute
          role={RoleChecker("Read", "collector")}
          component={CollectorPage2}
          path="/collector/:id/:status"
          exact
        />
        <PrivateRoute
          role={RoleChecker("Read", "collector")}
          component={CollectorPage2}
          path="/collector"
          exact
        />
        <PrivateRoute
          role={RoleChecker("Read Daftar Export", "list_export")}
          component={ExportListPage}
          path="/export/list"
          exact={true}
        />
        <PrivateRoute
          role={RoleChecker("Read Log Export", "log_export")}
          component={ExportListPage}
          path="/export/log-list"
          exact={true}
        />
        <PrivateRoute
          role={RoleChecker("Read Export Donation", "export_dona")}
          component={DonationExportPage}
          path="/export/donation"
          exact={true}
        />

        {/* LAPORAN ROLE */}
        {/*<PrivateRoute role={laporan_roles.includes(role)} component={ReportPage} path="/laporan"*/}
        {/*              exact/>*/}

        <PrivateRoute
          role={RoleChecker("Read", "analisa")}
          component={AnalysisPage}
          path="/analysis"
        />

        {/* KURBAN PAGE */}
        <PrivateRoute
          role={RoleChecker("Read", "thk_partnership")}
          component={PartnershipPage}
          path="/qurban/partnership"
          exact
        />
        <PrivateRoute
          role={RoleChecker("Create", "thk_partnership")}
          component={PartnershipEditDialog}
          path="/qurban/partnership/new"
          exact
        />
        <PrivateRoute
          role={RoleChecker("Edit", "thk_partnership")}
          component={PartnershipEditDialog}
          path="/qurban/partnership/:id/edit"
          exact
        />
        <PrivateRoute
          role={RoleChecker("Edit", "thk_partnership")}
          component={PartnershipManageCard}
          path="/qurban/partnership/:id/manage"
          exact
        />
        <PrivateRoute
          role={RoleChecker("Edit", "thk_partnership")}
          component={ThkReportTemplateCard}
          path="/qurban/partnership/:id/manage/edit/report_template"
          exact
        />
        <PrivateRoute
          role={RoleChecker("Edit", "thk_partnership")}
          component={ThkReportTemplateCard}
          path="/qurban/partnership/:id/manage/edit/report_certificate"
          exact
        />
        <PrivateRoute
          role={RoleChecker("Import", "thk_partnership")}
          component={ThkImportForm}
          path="/qurban/partnership/:id/manage/import-form"
          exact
        />
        <PrivateRoute
          role={RoleChecker("Import", "thk_partnership")}
          component={DetailImportBatch}
          path="/qurban/partnership/:batch_id/batch-import-detail"
          exact
        />

        {/* Menu Kurban */}
        <PrivateRoute
          role={RoleChecker("Read", "daftar_kurban")}
          component={KurbanPage}
          path="/qurban/kurban/kurban-list"
          exact={true}
        />
        {/* Hewan Kurban */}
        <PrivateRoute
          role={RoleChecker("Read", "hewan_kurban")}
          component={HewanKurbanPage}
          path="/qurban/kurban/hewan-kurban"
          exact={true}
        />
        <PrivateRoute
          role={RoleChecker("Create", "hewan_kurban")}
          component={HewanKurbanPage}
          path="/qurban/kurban/hewan-kurban/new"
          exact={true}
        />
        <PrivateRoute
          role={RoleChecker("Edit", "hewan_kurban")}
          component={HewanKurbanPage}
          path="/qurban/kurban/hewan-kurban/:id/edit"
          exact={true}
        />

        <PrivateRoute
          role={RoleChecker("Read", "hewan_kurban_campaign")}
          component={HewanKurbanCampaignPage}
          path="/qurban/kurban/hewan-kurban-campaign"
          exact={true}
        />
        <PrivateRoute
          role={RoleChecker("Create", "hewan_kurban_campaign")}
          component={HewanKurbanCampaignPage}
          path="/qurban/kurban/hewan-kurban-campaign/new"
          exact={true}
        />
        <PrivateRoute
          role={RoleChecker("Edit", "hewan_kurban_campaign")}
          component={HewanKurbanCampaignPage}
          path="/qurban/kurban/hewan-kurban-campaign/:id/edit"
          exact={true}
        />

        {/* Thk Period Template */}
        <PrivateRoute
          role={RoleChecker("Import", "thk_partnership")}
          component={PeriodTemplatePage}
          path="/qurban/period_template"
          exact
        />
        <PrivateRoute
          role={RoleChecker("Import", "thk_partnership")}
          component={PeriodTemplateEditDialog}
          path="/qurban/period_template/:file_type/:id/edit"
          exact
        />
        {/* Thk Channel Report */}
        <PrivateRoute
          role={RoleChecker("Read", "thk_channel_report")}
          component={ThkChannelReportPage}
          path="/qurban/thk_channel_report"
          exact
        />
        <PrivateRoute
          role={RoleChecker("Read", "thk_channel_report")}
          component={ThkChannelReportManage}
          path="/qurban/thk_channel_report/:id/list"
          exact
        />
        <PrivateRoute
          role={RoleChecker("Create", "thk_channel_report")}
          component={ThkChannelReportEditDialog}
          path="/qurban/thk_channel_report/new"
          exact
        />
        <PrivateRoute
          role={RoleChecker("Edit", "thk_channel_report")}
          component={ThkChannelReportEditDialog}
          path="/qurban/thk_channel_report/:id/edit"
          exact
        />

        {/* <PrivateRoute
          role={true}
          component={AolConfigPage}
          path="/aol-config"
          exact
        /> */}

        <PrivateRoute
          role={true}
          component={AolConfigPage}
          path="/config/aol-config"
          exact
        />

        <PrivateRoute
          role={true}
          component={AolBigQueryPage}
          path="/config/aol-big-query"
          exact
        />

        {/* > user-profile */}
        <ContentRoute
            path="/user-profile/change-password"
            component={ChangePassword}
          />
        {/* user-profile < */}

         {/* > Settlement Counter */}
         <ContentRoute
            path="/donasi/settlement"
            component={SettlementCounterPage}
          />
        {/* SettlementCounter < */}

        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
